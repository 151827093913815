<template>
  <v-container
    v-if="dataOptions.input"
    class="option-component options-parcels">
    <h2
      v-if="dataOptions.legend"
      class="legend darkGray--text">
      {{ dataOptions.legend }}
    </h2>
    <v-select
      v-if="isMobile"
      v-model="selected"
      :items="dataOptions.options"
      item-text="label"
      item-value="value"
      label="Escolha"
      single-line
      @input="changeSelect">
      <template v-slot:selection="data">
        {{ data.item.label }}
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item" />
        </template>
        <template v-else>
          <v-list-item-content>
            <v-list-item-title>
              {{ data.item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-select>
    <v-btn
      v-for="(option, index) in dataOptions.options"
      v-else
      :ref="`btn${index + 1}`"
      :key="index"
      :color="option.style"
      :disabled="option.disabled"
      class="option-element"
      depressed
      height="60"
      dark
      @click="send(index, option.value)">
      {{ option.label }}
    </v-btn>
  </v-container>
  <message
    v-else
    :data="data" />
</template>

<script>
  import Message from './Message'
  import chatUiMixin from '@/mixins/chat-ui'

  export default {
    components: { Message },
    mixins: [ chatUiMixin ],
    data () {
      return {
        selected: ''
      }
    },
    computed: {
      dataOptions () {
        return this.data
      }
    },
    watch: {
      data (val) {
        setTimeout(() => {
          this.startOptionsAnimation()
        }, 500)
      }
    },
    mounted () {
      this.$nextTick(() => {
        this.startOptionsAnimation()
      })
    },
    methods: {
      send (index, value) {
        let data = {
          id: this.data.id,
          value
        }
        this.endOptionsAnimation(index, data)
      },
      changeSelect () {
        let data = {
          id: this.data.id,
          value: this.selected
        }
        this.endOptionsAnimation(1, data)
      },
      startOptionsAnimation () {
        let children = this.$el.children
        let listTypes = [...children]

        listTypes.map((elem, index) => {
          let TIMELINE = this.anime.timeline({
            delay: 200 * (index + 1)
          })

          TIMELINE
            .add({
              targets: elem,
              duration: 500,
              opacity: [0, 1],
              scale: [0, 1]
            })
        })
      },
      endOptionsAnimation (index, option) {
        let children = this.$el.children
        let listTypes = [...children]
        let removedChild = listTypes[index]

        listTypes.splice(index, 1)
        listTypes.unshift(removedChild)

        listTypes.map((elem, index) => {
          let TIMELINE = this.anime.timeline({
            delay: 100 * index
          })

          TIMELINE
            .add({
              targets: elem,
              opacity: [1, 0],
              scale: [1, 0]
            })
        })

        // delay after selected
        setTimeout(() => {
          this.$emit('send', option)
        }, 100 * children.length)
      }
    }
  }
</script>

<style lang="sass">
  .option-element,
  .legend,
  .checkbox
    opacity: 0

  .options-parcels
    display: flex
    justify-content: center
    flex-wrap: wrap

    select
      -moz-appearance: menulist
      -webkit-appearance: menulist
      border: 1px solid $gray
      padding: 10px 20px

    .legend
      top: -40px
      position: absolute
      text-align: center
      font-size: 16px
      font-weight: 400

    .checkbox
      left: calc(50% - 260px)
      top: -30px
      position: absolute

  .option-element
    height: 60px
    margin: 0 5px

    .details
      text-transform: initial

    &:hover

      .v-tooltip
        transform: scale(1) translate(-50%, 0)
        opacity: 1


    .v-tooltip
      content: attr(data-total)
      position: absolute
      bottom: calc(100% + 10px)
      left: 50%
      transform: translateX(-50%)
      background: rgba($dark, .8)
      padding: 5px 10px
      border-radius: $radiusDefault
      font-size: 12px
      text-transform: capitalize

      transform: scale(.1) translate(-50%, 0)
      transform-origin: 0 100%
      transition: all ease-out .25s .1s, opacity ease-out .25s
      opacity: 0

      &::before
        content: ''
        border-left: 6px solid transparent
        border-right: 6px solid transparent
        border-top: 6px solid rgba($dark, .8)
        width: 0
        height: 0
        position: absolute
        left: 50%
        transform: translateX(-50%)
        bottom: -6px




  // @media screen and (max-width: $max-mobile)
  //   .option-component
  //     bottom: -15px

  //     .checkbox
  //       left: 10px
  //       top: 5px

  //       label
  //         font-size: 14px
  //         line-height: 1.4
  //         text-overflow: initial
  //         overflow: inherit
  //         white-space: initial
  //         max-width: 85%

</style>
